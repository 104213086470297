label, input, button {
    margin: 10px;
    padding: 5px;
}

footer {
    bottom: 0;
    position: absolute;
    width: 95%;
    height: 30px;
}